<template>
  <div class="app-container">
    <CrudTable entity="MessageTemplate" :columns="columns" :generate-summary="generateSummary" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import { formatOption } from '@/utils/filters';

export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        {
          field: 'messageType',
          label: 'messageTemplate.messageType',
          minWidth: '100px',
          sortable: false,
          formatter: formatOption(this, 'messageType'),
          filter: {
            type: 'select',
            options: this.$store.getters['option/loadedOptions']('messageType'),
            valueField: 'code',
            labelField: 'description'
          }
        },
        {
          field: 'code'
        }
      ]
    };
  },
  methods: {
    generateSummary(row) {
      const messageTypeDescription =
        row.messageType !== null &&
        this.$store.getters['option/loadedOptions']('messageType').find(item => item.code === row.messageType)
          .description;
      return `${messageTypeDescription} - ${row.code}`;
    }
  }
};
</script>

<style scoped></style>
